import Specification from './specification';
import AddProjectUnit from '../addCrmUnit';
import UnitArea from './unitArea';
import OtherSpec from './otherSpec';

export default {
  name: 'ProjectUnitDetails',
  props: ['crmUnitDetails'],
  components: {
    Specification,
    AddProjectUnit,
    UnitArea,
    OtherSpec
  },
  data() {
    return {
      actionName: null,
      loader: false,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      project: {
        value: null,
        text: null
      },
      editMode: false,
      activeTab: 'UnitDetails'
    };
  },
  mounted() {
    if (this.crmUnitDetails) {
      this.fillRecordCrmUnitDetails(this.crmUnitDetails);
    } else {
      this.editMode = true;
    }
  },
  methods: {
    fillRecordCrmUnitDetails(item) {
      this.legalEntity.value = item.le_id;
      this.legalEntity.text = item.le_name;
      this.operatingUnit.value = item.ou_id;
      this.operatingUnit.text = item.ou_name;
      this.project.value = item.crm_master_prj_id;
      this.project.text = item.crm_master_prj_name;
    },
    selectedTab(tabValue) {
      this.activeTab = tabValue;
    },
    closeTabs() {
      this.$emit('closeProjectUnitDetail');
      this.$emit('updateCrmUnitList');
    },
    buttonAccessPermissions(actionName) {
      this.actionName = actionName;
    }
  }
};
