import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import AddCrmUnit from './addCrmUnit';
import ProjectUnitDetails from './crmUnitDetails';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'CrmUnit',
  watch: {
    currentPage: function() {
      this.getCrmUnitList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCrmUnitList();
    }
  },
  components: {
    AddCrmUnit,
    ProjectUnitDetails
  },
  data() {
    return {
      unsubscribe: null,
      parent_value_set_id: null,
      showModalComponent: false,
      showValueSetModal: false,
      showDetails: false,
      orgType: null,
      openOuModal: false,
      loader: false,
      payload: {},
      editMode: false,
      showAlert: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      tab: null,
      defaultValue: {
        value: null,
        text: null
      },
      crmProject: {
        value: null,
        text: null
      },
      tower: {
        value: null,
        text: null
      },
      floor: {
        value: null,
        text: null
      },
      floorPlan: {
        value: null,
        text: null
      },
      active: true,
      unitDisplayName: null,
      unitName: null,
      crmUnitDetails: null,
      projectUnitData: [],
      projectUnitFields: [
        {
          key: 'crm_unit_display_name',
          label: 'Unit Display Name'
        },
        {
          key: 'crm_unit_name',
          label: 'Unit Name'
        },
        {
          key: 'prj_unit_name',
          label: 'PRJ Unit Name'
        },
        {
          key: 'crm_master_prj_name',
          label: 'Master Project Name'
        },
        {
          key: 'crm_sector_name',
          label: 'Sector Name'
        },
        {
          key: 'crm_pocket_name',
          label: 'Pocket Name'
        },
        {
          key: 'crm_prj_name',
          label: 'Project Name'
        },
        {
          key: 'crm_floor_name',
          label: 'Floor Name'
        }
      ]
    };
  },
  validations: {
    crmProject: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideModalComponent(true, 'AddCrmUnit');
          this.crmUnitDetails = null;
        }
        if (actionName === 'download' && !this.showModalComponent) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'crmInventoryProperty/getCrmUnitList',
            'crm-unit',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'CRMProject') {
        return (this.childName = 'CRM Project');
      } else if (tab === 'CRMTowers') {
        return (this.childName = 'CRM Towers');
      } else if (tab === 'CRMSector') {
        return (this.childName = 'CRM Sector');
      } else if (tab === 'AddCrmUnit') {
        return (this.childName = 'Add CRM Unit');
      }
    },
    getCrmUnitList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          page: this.currentPage - 1,
          limit: this.perPage,
          crm_prj_id: this.crmProject.value,
          crm_floor_id: this.floor.value,
          crm_unit_name: this.unitName
        };
        this.loader = true;
        this.$store
          .dispatch('crmInventoryProperty/getCrmUnitList', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.projectUnitData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    hideModalComponent(flag, component) {
      this.showModalComponent = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    rowSelected(rowData) {
      this.crmUnitDetails = rowData;
      this.$emit('towerDetails', this.parentProjectTowerData);
      this.showDetails = true;
    },
    projectDetails(item) {
      this.showModalComponent = false;
      this.project = {
        value: item.project_id,
        text: item.project_name
      };
    },
    towerDetails(item) {
      this.showModalComponent = false;
      this.tower = {
        value: item.tower_id,
        text: item.tower_name
      };
    },
    floorDetails(item) {
      this.showModalComponent = false;
      this.floor = {
        value: item.project_floor_id,
        text: item.floor_name
      };
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_PROJECT) {
        this.crmProject = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_TOWER) {
        this.tower = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_FLOOR) {
        this.floor = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_PROJECT) {
        this.parent_value_set_id = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_TOWER) {
        this.parent_value_set_id = this.crmProject.value;
      } else if (vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_FLOOR) {
        this.parent_value_set_id = this.tower.value;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    closeProjectUnitDetail() {
      this.showDetails = false;
    },
    updateCrmUnitList() {
      this.getCrmUnitList();
    },
    resetSearchFilters() {
      this.crmProject = this.defaultValue;
      this.tower = this.defaultValue;
      this.floor = this.defaultValue;
      this.unitName = null;
      this.projectUnitData = [];
      this.parent_value_set_id = null;
      this.totalRows = null;
      this.currentPage = 1;
      // this.active = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.crmProject.value) {
        this.crmProject = this.defaultValue;
      } else if (vsetCode === this.tower.value) {
        this.tower = this.defaultValue;
      } else if (vsetCode === this.floor.value) {
        this.floor = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
