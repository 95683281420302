import DatePicker from 'vue2-datepicker';
import Organization from '../../../admin/organization';
import PrjUnit from '../../../project/projectUnit';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'AddProjectUnit',
  // props: {
  //   crmUnitDetails: Object,
  //   showDetails: Boolean
  // },
  props: ['crmUnitDetails', 'showDetails'],
  components: {
    DatePicker,
    Organization,
    PrjUnit
  },
  data() {
    return {
      unsubscribe: null,
      showPrjUnitModule: false,
      showModalComponent: false,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      childName: null,
      parent_value_set_id: null,
      tab: null,
      tempParentData: null,
      isDependent: false,
      defaultValue: {
        value: null,
        text: null
      },
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      masterPrj: {
        value: null,
        text: null
      },
      sector: {
        value: null,
        text: null
      },
      pocket: {
        value: null,
        text: null
      },
      project: {
        value: null,
        text: null
      },
      tower: {
        value: null,
        text: null
      },
      floor: {
        value: null,
        text: null
      },
      floorPlan: {
        value: null,
        text: null
      },
      prjUnit: {
        value: null,
        text: null
      },
      prjProject: {
        value: null,
        text: null
      },
      unitDisplayName: null,
      unitName: null,
      unitId: 0,
      crmUnitId: 0,
      startDate: null,
      endDate: null,
      version: null,
      active: true
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditCrmUnit();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.unitId });
        }
      }
    });
    if (this.crmUnitDetails !== null && this.crmUnitDetails !== undefined) {
      this.legalEntity = {
        value: this.crmUnitDetails.le_id,
        text: this.crmUnitDetails.le_name
      };
      this.operatingUnit = {
        value: this.crmUnitDetails.ou_id,
        text: this.crmUnitDetails.ou_name
      };
      this.masterPrj = {
        value: this.crmUnitDetails.crm_master_prj_id,
        text: this.crmUnitDetails.crm_master_prj_name
      };
      this.sector = {
        value: this.crmUnitDetails.crm_sector_id,
        text: this.crmUnitDetails.crm_sector_name
      };
      this.pocket = {
        value: this.crmUnitDetails.crm_pocket_id,
        text: this.crmUnitDetails.crm_pocket_name
      };
      this.project = {
        value: this.crmUnitDetails.crm_prj_id,
        text: this.crmUnitDetails.crm_prj_name
      };
      this.tower = {
        value: this.crmUnitDetails.crm_tower_id,
        text: this.crmUnitDetails.crm_tower_name
      };
      this.floor = {
        value: this.crmUnitDetails.crm_floor_id,
        text: this.crmUnitDetails.crm_floor_name
      };
      this.prjUnit = {
        value: this.crmUnitDetails.prj_unit_id,
        text: this.crmUnitDetails.prj_unit_name
      };
      this.unitDisplayName = this.crmUnitDetails.crm_unit_display_name;
      this.unitId = this.crmUnitDetails.prj_unit_id;
      this.unitName = this.crmUnitDetails.prj_unit_name;
      this.active = this.crmUnitDetails.active;
      this.startDate = this.crmUnitDetails.start_date;
      this.endDate = this.crmUnitDetails.end_date;
      this.crmUnitId = this.crmUnitDetails.crm_unit_id;
    } else {
      this.editMode = true;
    }
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'DefineProject') {
        return (this.childName = 'Define Project');
      } else if (tab === 'ProjectTowers') {
        return (this.childName = 'Project Towers');
      } else if (tab === 'ProjectFloors') {
        return (this.childName = 'Project Floors');
      }
    },
    addEditCrmUnit() {
      const payload = {
        crm_floor_id: this.floor.value,
        crm_pocket_id: this.pocket.value,
        crm_prj_id: this.project.value,
        crm_unit_display_name: this.unitDisplayName,
        crm_unit_id: this.crmUnitId,
        crm_unit_name: this.unitName,
        prj_unit_id: this.prjUnit.value
      };
      this.loader = true;
      this.$store
        .dispatch('crmInventoryProperty/addEditCrmUnit', payload)
        .then(response => {
          this.showAlert = true;
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.crmUnitId = response.data.data.crm_unit_id;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.parent_value_set_id = this.legalEntity.value;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.parent_value_set_id = this.operatingUnit.value;
      }
      if (this.vsetCode === 'crm_master_project') {
        this.masterPrj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.parent_value_set_id = this.masterPrj.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CRM_SECTOR) {
        this.sector = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.parent_value_set_id = this.sector.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CRM_POCKET) {
        this.pocket = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_PROJECT) {
        this.project = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.prjProject = {
          value: item.prj_id,
          text: item.prj_name
        };
        this.parent_value_set_id = this.project.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_TOWER) {
        this.tower = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.parent_value_set_id = this.tower.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_FLOOR) {
        this.floor = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === 'prj_floor_plan') {
        this.floorPlan = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_PROJECT) {
        this.parent_value_set_id = this.operatingUnit.value;
        this.isDependent = true;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedPrjUnitName(item) {
      this.prjUnit = {
        value: item.project_unit_id,
        text: item.unit_name
      };
      this.showPrjUnitModule = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    hideModalComponent(flag, component) {
      this.showModalComponent = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    hidePrjUnitModule(flag) {
      this.showPrjUnitModule = flag;
    },
    openCloseOu(flag, orgType, component) {
      this.tab = component;
      this.orgType = orgType;
      const dataToGetOrg = {
        orgType: orgType,
        parentLeId: null
      };
      this.$store.dispatch('shared/setUnsetOrgType', dataToGetOrg);
      this.setChildName(this.tab);
      this.showModalComponent = flag;
    },
    selectedOrg(item) {
      if (this.orgType === 'LE') {
        this.leName.value = item.org_id;
        this.leName.text = item.org_name;
      } else if (this.orgType === 'OU') {
        this.ouName.value = item.org_id;
        this.ouName.text = item.org_name;
      } else if (this.orgType === 'LOC') {
        this.location.value = item.org_id;
        this.location.text = item.org_name;
      }
      this.showModalComponent = false;
    },
    projectDetails(item) {
      this.showModalComponent = false;
      this.project = {
        value: item.project_id,
        text: item.project_name
      };
    },
    towerDetails(item) {
      this.showModalComponent = false;
      this.tower = {
        value: item.tower_id,
        text: item.tower_name
      };
    },
    floorDetails(item) {
      this.showModalComponent = false;
      this.floor = {
        value: item.project_floor_id,
        text: item.floor_name
      };
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
      } else if (vsetCode === this.operatingUnit.value) {
        this.operatingUnit = this.defaultValue;
      } else if (vsetCode === this.masterPrj.value) {
        this.masterPrj = this.defaultValue;
      } else if (vsetCode === this.sector.value) {
        this.sector = this.defaultValue;
      } else if (vsetCode === this.pocket.value) {
        this.pocket = this.defaultValue;
      } else if (vsetCode === this.project.value) {
        this.project = this.defaultValue;
      } else if (vsetCode === this.tower.value) {
        this.tower = this.defaultValue;
      } else if (vsetCode === this.floor.value) {
        this.floor = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
