import appStrings from '@/app/utility/string.utility';
export default {
  name: 'UnitArea',
  props: {
    crmUnitDetails: Object
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      projectUnitId: null,
      projectUnitAreaId: 0,
      areaType: null,
      superArea: null,
      defaultValue: {
        value: null,
        text: null
      },
      superAreaUnit: {
        value: null,
        text: null
      },
      capetArea: null,
      capetAreaUnit: {
        value: null,
        text: null
      },
      landArea: null,
      landAreaUnit: {
        value: null,
        text: null
      },
      basementArea: null,
      basementAreaUnit: {
        value: null,
        text: null
      },
      terraceArea: null,
      terraceAreaUnit: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    this.getUomClass();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditProjectUnitArea();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    this.unitId = this.crmUnitDetails.prj_unit_id;
    this.getProjectUnitArea();
  },
  methods: {
    getProjectUnitArea() {
      const payload = {
        prjUnitId: this.unitId
      };
      this.loader = true;
      this.$store
        .dispatch('projects/getProjectUnitArea', payload)
        .then(response => {
          this.loader = false;
          const results = response.data.data;
          this.unitId = results.project_unit_id;
          this.projectUnitAreaId = results.project_unit_area_id;
          this.superArea = results.super_area;
          this.superAreaUnit = {
            value: results.super_area_uom_vset,
            text: results.super_area_uom_vset_meaning
          };
          this.terraceArea = results.terrace_area;
          this.terraceAreaUnit = {
            value: results.terrace_area_uom_vset,
            text: results.terrace_area_uom_vset_meaning
          };
          this.landArea = results.land_area;
          this.landAreaUnit = {
            value: results.land_area_uom_vset,
            text: results.land_area_uom_vset_meaning
          };
          this.basementArea = results.basement_area;
          this.basementAreaUnit = {
            value: results.basement_area_uom_vset,
            text: results.basement_area_uom_vset_meaning
          };
          this.capetArea = results.carpet_area;
          this.capetAreaUnit = {
            value: results.carpet_area_uom_vset,
            text: results.carpet_area_uom_vset_meaning
          };
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditProjectUnitArea() {
      const payload = {
        super_area: this.superArea,
        super_area_uom_vset: this.superAreaUnit.value,
        carpet_area: this.capetArea,
        carpet_area_uom_vset: this.capetAreaUnit.value,
        land_area: this.landArea,
        land_area_uom_vset: this.landAreaUnit.value,
        basement_area: this.basementArea,
        basement_area_uom_vset: this.basementAreaUnit.value,
        terrace_area: this.terraceArea,
        terrace_area_uom_vset: this.terraceAreaUnit.value,

        project_unit_area_id: this.projectUnitAreaId,
        project_unit_id: this.unitId
      };
      this.loader = true;
      this.$store
        .dispatch('projects/addEditProjectUnitArea', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            const results = response.data.data;
            this.projectUnitAreaId = results.project_unit_area_id;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getUomClass() {
      this.$store
        .dispatch('template/getLOVBySetType', 'UOM_CLASS')
        .then(response => {
          if (response.status === 200) {
            const filterResult = response.data.data;
            const getValueAndText = filterResult.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning,
                detail_id: type.value_set_dtl_id
              };
            });
            this.intervalClass = getValueAndText.filter(
              item => item.value === 'AREA'
            );
          }
        });
    },

    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        this.superAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.capetAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.landAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.basementAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.terraceAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode, areaType) {
      this.parent_value_set_id = this.intervalClass[0].detail_id;
      this.vsetCode = vsetCode;
      this.areaType = areaType;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.superAreaUnit.value) {
        this.superAreaUnit = this.defaultValue;
      } else if (vsetCode === this.capetAreaUnit.value) {
        this.capetAreaUnit = this.defaultValue;
      } else if (vsetCode === this.landAreaUnit.value) {
        this.landAreaUnit = this.defaultValue;
      } else if (vsetCode === this.basementAreaUnit.value) {
        this.basementAreaUnit = this.defaultValue;
      } else if (vsetCode === this.terraceAreaUnit.value) {
        this.terraceAreaUnit = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
